<template>
  <div id="password" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
    <div class="header flex">
      <div class="back flex items-center justify-center mr-auto">
          <!-- <feather-icon icon="ArrowLeftIcon"></feather-icon> -->
      </div>
      <div class="title flex items-center uppercase">Change Password</div>
      <div class="save flex items-center justify-center ml-auto">
          <feather-icon icon="CheckIcon"></feather-icon>
      </div>
    </div>
    <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" type="password" label="Old Password" v-model="form.old_password" 
            :danger="validation.hasError('form.old_password')" val-icon-danger="clear"
            :danger-text="validation.firstError('form.old_password')" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" type="password" label="New Password" v-model="form.password" 
            :danger="validation.hasError('form.password')" val-icon-danger="clear"
            :danger-text="validation.firstError('form.password')" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" type="password" label="Confirm Password" v-model="form.confirm_password" 
            :danger="validation.hasError('form.confirm_password')" val-icon-danger="clear"
            :danger-text="validation.firstError('form.confirm_password')"/>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">Save</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import SimpleVueValidation from 'simple-vue-validator'
const Validator = SimpleVueValidation.Validator
Vue.use(SimpleVueValidation)

export default {
  data() {
    return {
      submitted: false,
      form: {
        password: "",
        confirm_password: "",
        old_password: ""
      }
    }
  },
  validators: {
    "form.old_password": {
      debounce: 500,
      validator: function(value) {
        return Validator.value(value).required()
      }
    },
    "form.password": {
      debounce: 500,
      validator: function(value) {
        return Validator.value(value)
            .required()
            .minLength(8)
            .regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm, "Password must have uppercase letters, lowercase letters, and numbers")
      }
    },
    "form.confirm_password, form.password": {
      debounce: 500,
      validator: function (conf, pass) {
        if (this.submitted || this.validation.isTouched('form.confirm_password')) {
          return Validator.value(conf)
            .required()
            .match(pass);
        }
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    save() {
      this.submitted = true
      this.$validate().then(result => {
        if (result) {
          this.$vs.loading()
          this.action().then(async () => {
            this.$vs.loading.close()
            this.$vs.notify({
                title: 'Success!',
                text: 'Password berhasil diubah.',
                color: 'success',
                position: 'top-center'
            })
            await this.clearForm();
            this.validation.reset();
          }).catch(error => {
            this.$vs.loading.close()
            this.$vs.notify({
                title: 'Error!',
                text: error.response.data.message,
                color: 'danger',
                position: 'top-center'
            })
          })
        }
      })
    },
    action() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: 'v1/password',
          method: 'PUT',
          data: this.form
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      })
    },
    clearForm() {
      this.form.password = ""
      this.form.old_password = ""
      this.form.confirm_password = ""
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuesax/_variables.scss";

#password {
  min-height: 400px;
  background-color: #ffffff;
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
  border-radius: .5rem;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  .header {
      padding-top: 20px;
      margin-bottom: 40px;

      .help-toggle {
          background: rgba(var(--vs-primary), 1) !important;

          span {
              font-size: 16px;
              color: rgba(var(--vs-primary), 1) !important;
          }
      }

      .back {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          cursor: pointer;

          .feather-icon {
              width: 20px;
              height: 20px;
          }
      }

      .title {
          color: #444444;
          font-weight: 600;
          font-size: 1.3rem;
          line-height: 1;
      }

      .save {
          width: 40px;
          height: 40px;
          background: rgba(var(--vs-primary), 1) !important;
          border-radius: 20px;
          cursor: pointer;

          .feather-icon {
              color: $white;
              width: 20px;
              height: 20px;
          }
      }
  }
}
</style>